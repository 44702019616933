import React from 'react';
import Layout from '../components/Layout';
import Grid from '@material-ui/core/Grid/Grid';
import Typography from '@material-ui/core/Typography/Typography';

export default () => {
  const content = (
    <Grid container className="page__content">
      <Grid item className="page__col">
        <div className="page__section">
          <Typography variant="h2">404: Page Not Found</Typography>
        </div>
      </Grid>
    </Grid>
  );

  return (
    <Layout
      components={[{ columns: 12, content, className: "not-found" }]}
      authRequired={false}
    />
  )
};